var vmProject = {}
var header
$(function () {
  $('video').attr('uc-video-toolbar-id', '')
  header = new Vue({
    el: '#login-bar',
    data: function data() {
      return {
        isLoading: false,
        showAddress: false,
        addrCode: false,
        ins: 0,
        city: '',
        val: null,
        // provinceList: [], // 省市列表
        provinceList: this.formatList(addressList, 'areaShort', 'areaName'),
        // 省市列表
        letterList: [
          'A',
          'B',
          'C',
          'D',
          'E',
          'F',
          'G',
          'H',
          'I',
          'J',
          'K',
          'L',
          'M',
          'N',
          'O',
          'P',
          'Q',
          'R',
          'S',
          'T',
          'U',
          'V',
          'W',
          'X',
          'Y',
          'Z'
        ],
        //检索字母
        cityNameList: [],
        //城市
        cityList: [],
        // 区列表
        streetList: [],
        // 街道列表
        provinceActive: null,
        // 省市激活样式
        cityActive: null,
        // 区激活
        streetActive: null,
        // 街道激活
        cityValue: '',
        // 区值
        streetValue: '',
        // 街道值
        provinceValue: '',
        //省市值
        areaValue: '',
        // 办理地区值
        areaNo: 0,
        //地区号
        queryTypeList: [],
        //search搜索出来的分类
        queryTypeStatus: false,
        searchVal: '',
        cityValueCopy: '',
        cityVal: '',
        onsearch: false,
        //搜索结果列表key
        searchList: [],
        //搜索结果
        codeIndex: 0,
        //监听上下键
        tipsShow: false,
        Unreadlist: [],
        firstLoad: true, //是否为首次进入页面
        hotcity: []
      }
    },
    mounted: function mounted() {
      this.isLoading = true
      this.getHotCityList()
    },
    created: function created() {
      // if (window.location.pathname === '/project-list-new') {
      //   this.firstLoad = true
      // } else {
      //   this.firstLoad = true
      // }

      let showCity =
        window.location.pathname !== '/register-2'
          ? '全国'
          : $.cookie('areaName')
      // console.log($.cookie('areaName'), showCity)
      this.cityValue = showCity
      this.areaValue = showCity
      this.$watch('cityValue', function (news, old) {
        // this.firstLoad = false
        // this.cityValue = news
        if (window.location.pathname === '/') {
          city.cityValue = news
        }
        this.refreshData()
      })
      let showId =
        window.location.pathname !== '/register-2' ? 0 : $.cookie('areaId')
      this.areaNo = showId
      this.addressConfirm()
      this.getUnreadMessage()
    },
    watch: {
      // cityValue: function (news, old) {
      //   try {
      //     city.cityValue = news
      //     this.firstLoad = false
      //   } catch (error) { }
      // }
    },
    methods: {
      // 获取热门城市列表
      getHotCityList: function () {
        var _this = this
        $.post('/getHotCityList', function (result) {
          var data = JSON.parse(result)
          if (data.code == 0) {
            _this.hotcity = data.content
          }
        })
      },
      getUnreadMessage: function getUnreadMessage() {
        var _this = this
        $.post('/UnreadMessage', function (result) {
          var data = JSON.parse(result)
          if (data.code == 0) {
            _this.Unreadlist = data.content
            _this.Unreadlist.forEach(function (item, index, arr) {
              if (item.business_type == 3 && item.count > 0) {
                _this.tipsShow = true
              }
            })
          }
        })
      },
      formatList: function formatList(arr, keyword, name) {
        var newArr1 = []
        var tempArr = []
        var allCityName = []
        var reg = /\b(\w)|\s(\w)/g
        var k = 0
        arr.sort(function (a, b) {
          var x = a[keyword]
          var y = b[keyword]
          return x < y ? -1 : x > y ? 1 : 0
        })
        var firstWord = arr[0][keyword].charAt(0).toUpperCase()
        //获取第一个分类字母
        arr.map(function (v) {
          allCityName.push({
            areaName: v[name],
            areaId: v.areaId,
            areaShort: v.areaShort
          })
          v.areaDictionaryVOList.map(function (keyName) {
            //二级列表根据首字母分类
            allCityName.push({
              areaName: keyName[name],
              areaId: keyName.areaId,
              areaShort: keyName.areaShort
            })
          })
          v[keyword] = v[keyword].replace(reg, function (m) {
            return m.toUpperCase()
          })
          //首字母大写
          if (firstWord == v[keyword].charAt(0)) {
            tempArr.push(v)
            newArr1[k] = {
              Title: firstWord,
              List: tempArr
            }
          } else {
            //这里循环到这表示已经第二个字母了
            firstWord = v[keyword].charAt(0)
            //设置第二字母
            tempArr = []
            //把之前的清除掉
            tempArr.push(v)
            //添加第一个
            newArr1[++k] = {
              //自增
              Title: firstWord,
              List: tempArr
            }
          }
        })
        // return [newArr1, allCityName]
        return {
          newArr1: newArr1,
          allCityName: allCityName
        }
      },
      //上键
      goUp: function goUp() {
        if (this.codeIndex > 0) {
          return this.codeIndex--
        }
      },
      //下键
      goDown: function goDown(length) {
        if (this.codeIndex < length) return this.codeIndex++
      },
      //根据字搜索城市
      search: function search(keywords) {
        var _this2 = this
        this.searchList = []
        if (keywords != '') {
          this.onsearch = false
          this.provinceList.allCityName.filter(function (item) {
            if (item != null) {
              var keyLetter = item.areaShort.toLowerCase()
              if (item.areaName.includes(keywords)) {
                //根据文字检索城市列表
                _this2.searchList.push(item)
                _this2.onsearch = true
              } else {
                if (keyLetter.indexOf(keywords) != -1) {
                  //根据拼音检索城市列表
                  _this2.searchList.push(item)
                  _this2.onsearch = true
                }
              }
            }
          })
          if (!this.onsearch) {
            this.queryTypeStatus = true
            this.onsearch = false
          } else {
            this.queryTypeStatus = false
            this.onsearch = true
          }
        } else {
          this.onsearch = false
          this.queryTypeStatus = false
        }
      },
      // 地址确认
      addressConfirm: function addressConfirm() {
        this.areaValue = this.provinceValue + this.cityValue + this.streetValue
        var expiresDate = new Date()
        expiresDate.setTime(expiresDate.getTime() + 3 * 24 * 60 * 60 * 1000)
        if (this.cityValue) {
          $.cookie('areaName', this.cityValue, {
            path: '/',
            expires: expiresDate,
            // domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaId', this.areaNo, {
            path: '/',
            expires: expiresDate,
            // domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaProvince', this.provinceValue, {
            path: '/',
            // expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaDistrict', this.cityValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          this.showAddress = false
          // 异步刷新招标中心列表以及指数数据
          if (this.firstLoad === false) {
            this.refreshData()
          }
        } else if (this.provinceValue) {
          $.cookie('areaName', this.provinceValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaId', this.areaNo, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaProvince', this.provinceValue, {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          $.cookie('areaDistrict', '', {
            path: '/',
            expires: expiresDate,
            domain: document.domain.replace(/^[^\.]+\./, ''),
            secure: false
          })
          this.cityValueCopy = ''
          this.cityValue = this.provinceValue
          this.showAddress = false
          // 异步刷新招标中心列表以及指数数据
          if (this.firstLoad === false) {
            this.refreshData()
          }
        } //  else {
        //     showAlert('#alert2', '市级地址为必选项')
        // }
      },
      // 选择省市
      selectProvince: function selectProvince(item, index) {
        this.areaNo = item.areaId
        this.provinceActive = index
        this.provinceValue = item.areaName
        this.streetList = []
        this.cityActive = null
        this.streetActive = null
        this.cityValue = ''
        this.streetValue = ''
        if (this.provinceValue === '\u5168\u56FD') this.cityList = []
        if (item.areaDictionaryVOList && item.areaDictionaryVOList != []) {
          this.cityList = item.areaDictionaryVOList
          this.addressConfirm()
        }
      },
      // 选择市区
      selectCity: function selectCity(item, index) {
        this.areaNo = item.areaId
        this.cityActive = index
        this.cityValue = item.areaName
        this.cityValueCopy = item.areaName
        this.streetActive = null
        this.streetValue = ''
        // if (item.areaDictionaryVOList && item.areaDictionaryVOList != []) {
        this.streetList = item.areaDictionaryVOList
        this.addressConfirm() // }
      },
      selectHotCity: function selectHotCity(areaName, areaId) {
        this.areaNo = areaId
        this.provinceValue = areaName
        this.streetList = []
        this.cityActive = null
        this.streetActive = null
        this.cityValue = ''
        this.streetValue = ''
        if (this.provinceValue === '\u5168\u56FD') this.cityList = []
        this.addressConfirm()
        this.onsearch = false
        this.searchList = []
      },
      // 选择街道
      selectStreet: function selectStreet(item, index) {
        this.areaNo = item.areaId
        this.streetActive = index
        this.streetValue = item.areaName
      },
      // 清空地址
      clearAddress: function clearAddress() {
        this.cityList = []
        this.streetList = []
        this.provinceActive = null
        this.cityActive = null
        this.streetActive = null
        this.provinceValue = $.cookie('areaName')
        this.areaNo = $.cookie('areaId')
        this.cityValue = ''
        this.streetValue = ''
      },
      // 异步刷新招标中心列表以及指数数据
      refreshData: function refreshData() {
        let _this = this
        console.log('vmProject招标中心', vmProject)
        if (vmProject && typeof vmProject != 'undefined') {
          var index = $('.tab ul li.active').data('index')
          if (window.location.pathname === '/project-list-new') {
            console.log('进入')
            vmProject.getNewProjectList(
              null,
              null,
              null,
              null,
              null,
              vmProject.params.projectType
            )
          } else {
            vmProject.getProjectList(index, null, 1, null, null)
          }
          vmProject.getTrade()
        }
        if (typeof vm != 'undefined') {
          // var city = ''
          this.city = ''
          if (this.cityValue === '\u5168\u56FD') {
            this.city = ''
          } else {
            this.city = this.cityValueCopy != '' ? this.cityValue : ''
          }
          vm.areaValue = this.provinceValue + this.city
        }
      },
      //搜索类别
      searchTypeFn: function searchTypeFn(e) {
        this.throttle(this.queryTypeDataFn, null, 500, e.target.value)
      },
      hideSearchType: function hideSearchType() {
        var _this = this
        setTimeout(function () {
          _this.queryTypeStatus = false
        })
      },
      getSearchType: function getSearchType(item) {
        window.location.href = '/product/' + item.id
      },
      //节流
      throttle: function throttle(fn, context, delay, text) {
        clearTimeout(fn.timeoutId)
        fn.timeoutId = setTimeout(function () {
          fn.call(context, text)
        }, delay)
      },
      queryTypeDataFn: function queryTypeDataFn(val) {
        var _this = this
        var queryMap = {
          keyword: val,
          pageNo: 1,
          size: 10,
          searchType: 1
        }
        var queryMap = JSON.stringify(queryMap)
        $.post(
          '/search',
          {
            queryMap: queryMap
          },
          function (result) {
            var result = JSON.parse(result)
            if (result.code == 0) {
              var data = result.content.productMap
              _this.queryTypeList = data
              _this.queryTypeStatus = true
            }
          }
        )
      },
      searchFn: function searchFn() {
        window.location.href =
          '/search?keyword=' + this.searchVal + '&searchTabindex=1'
      },
      ctive: function ctive(num) {
        this.ins = num
        var top = parseInt(this.$refs.elememt[num].offsetTop) + 30
        $('.addressSelect').scrollTop(top)
      }
    }
  })
  var authSuccess = false
  var authing = false
  //成为服务者弹框控制
  getServerAuthenticationInfo()
  // 获取服务者认证信息
  function getServerAuthenticationInfo() {
    let params = {}
    $.ajax({
      type: 'post',
      url: '/getServerAuthenticationInfo',
      dataType: 'json',
      data: params,
      success: function success(result) {
        if (result.code == 0) {
          if (result.content.authStatus == 2) {
            //已认证
            if ($.cookie('token')) {
              authSuccess = true
              authing = false
              $('.asServerAlert a').removeAttr('href')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          } else if (result.content.authStatus == 1) {
            //审核中
            if ($.cookie('token')) {
              authing = true
              authSuccess = false
              $('.asServerAlert a').removeAttr('href')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          } else if (result.content.authStatus == 3) {
            if ($.cookie('token')) {
              authing = false
              authSuccess = false
              $('.asServerAlert a').attr('href', '/serverAuthentication')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          } else {
            if ($.cookie('token')) {
              authing = false
              authSuccess = false
              $('.asServerAlert a').attr('href', '/serverAuthentication')
            } else {
              $('.asServerAlert a').attr('href', '/login')
            }
          }
        }
      }
    })
  }
  $('.my-cat').hover(
    function () {
      $('.my-cat i').css({
        background:
          'url(/images/server-authentication/arrow-down.png) 0 0 no-repeat',
        'background-size': '100%'
      })
      $('.menu-list').css('display', 'flex')
    },
    function () {
      $('.my-cat i').css({
        background:
          'url(/images/server-authentication/arrow-up.png) 0 0 no-repeat',
        'background-size': '100%'
      })
    }
  )
  $('.menu-list').hover(
    function () {
      $('.my-cat i').css({
        background:
          'url(/images/server-authentication/arrow-down.png) 0 0 no-repeat',
        'background-size': '100%'
      })
      $('.menu-list').css('display', 'flex')
    },
    function () {
      $('.my-cat i').css({
        background:
          'url(/images/server-authentication/arrow-up.png) 0 0 no-repeat',
        'background-size': '100%'
      })
      $('.menu-list').css('display', 'none')
    }
  )
  $('.asServerAlert a').click(function () {
    if (authing == true) {
      toast('您的认证信息正在审核中，请耐心等待')
    }
  })

  function noPassByMobile(str) {
    console.log('noPassByMobile11', str)
    if (null != str && str != undefined) {
      var pat = /(\d{3})\d*(\d{4})/;
      return str.replace(pat, '$1****$2');
    } else {
      return "";
    }
  }
  if ($.cookie('token')) {
    $('.asServerAlert a').attr('href', '/serverAuthentication')
    $('.top-nav li:nth-child(2) a').attr('href', '/users')
    $('.top-nav li:nth-child(3) a').attr('href', '/users/order-list')
    $('.top-nav li:nth-child(4) a').attr('href', '/users/personal-message')
    $('.top-nav-login').show()
    $('.top-nav-nologin').hide()
    $('.user-center').attr('href', '/users')
    $('.my-order').attr('href', '/users/order-list')
    $('.my-notice').attr('href', '/users/personal-message')
    // $('.conact-us').attr('href', '/login')
    $('.my-invoice').attr('href', '/users/my-invoice')
    $('.my-wallet').attr('href', '/users/mywallet')
    // $('.partner-center').attr('href', '/login')
    // $('.top-nav-login a')
    //   .eq(0)
    //   .text(noPassByMobile($.cookie('phone')))
  } else {
    $('.asServerAlert a').attr('href', '/login')
    $('.top-nav li:nth-child(2) a').attr('href', '/login')
    $('.top-nav li:nth-child(3) a').attr('href', '/login')
    $('.top-nav li:nth-child(4) a').attr('href', '/login')
    $('.user-center').attr('href', '/login')
    $('.my-order').attr('href', '/login')
    $('.my-notice').attr('href', '/login')
    $('.conact-us').attr('href', '/login')
    $('.my-invoice').attr('href', '/login')
    $('.my-wallet').attr('href', '/login')
    $('.partner-center').attr('href', '/login')
    $('.top-nav-login').hide()
    $('.top-nav-nologin').show()
    $('.top-nav-login a')
      .eq(0)
      .text('')
  }
  $('.close-header-adv').click(function () {
    $('.adv-bar').slideUp(300)
  })
  var oheight = $('.topbanner2wang').height()
  $('.topbanner2wang')
    .delay(3000)
    .animate({ height: '0px', opacity: '0' }, 'slow')
  $('.down').click(function () {
    $('.topbanner2wang')
      .stop()
      .animate({ height: oheight, opacity: '1' }, 'slow')
    $('.down').hide()
    $('.up').show()
  })
  $('.up').click(function () {
    $('.topbanner2wang')
      .stop()
      .animate({ height: '0px', opacity: '0' }, 'slow')
    $('.down').show()
    $('.up').hide()
  })
})
